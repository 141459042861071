const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://de4yzn1w46.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://qlzy0mnlv2.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'hotfix/CV4-2316-hotfix-all-company-structure-to-be-shown-device-config',
    HOSTNAME: 'https://teams-service.testing.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://rs4k9t9nml.execute-api.us-west-2.amazonaws.com/testing'
  },
};

export default config;
